import React, { useState } from "react";
import "../assets/style/globalStyle.scss";
import NavBar from "../components/navBarSite";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faBasketballBall, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";


const BnCoachingPage = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const [styleHover, setStyleHover] = useState(false);
  const [styleHover1, setStyleHover1] = useState(false);
  const [styleHover2, setStyleHover2] = useState(false);
  const [styleHover3, setStyleHover3] = useState(false);



  const styles = {
    container: {
      margin: 0,
      padding: 0,
      fontFamily: "Montserrat",
    },
    btnMore1: {
      fontSize: "16px",
      color: "white",
      borderRadius: 10,
      paddingRight: "28px",
      paddingLeft: "28px",
      paddingTop: "15px",
      paddingBottom: "15px",
      letterSpacing: "1px",
      backgroundColor: "black",
      fontWeight: "bold",
      marginTop: 50,
      marginRight: 25,
      marginLeft: 25
    },
    btnMoreHover1: {
      fontSize: "16px",
      color: "white",
      borderRadius: 10,
      paddingRight: "28px",
      paddingLeft: "28px",
      paddingTop: "15px",
      paddingBottom: "15px",
      letterSpacing: "1px",
      fontWeight: "bold",
      backgroundImage: `linear-gradient(180deg, rgba(252, 206, 192, 1),
      rgba(138, 80, 128, 1))`,
      marginTop: 50,
      marginRight: 25,
      marginLeft: 25
    },
    videoSection: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      marginTop: 80,
      marginBottom: 70,
      justifyContent: "center",
      height: "90vh",
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5))`,
    },
    sections: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      marginTop: 70,
      marginBottom: 70,
    },
    backgroundSections: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      marginTop: 70,
      marginBottom: 70,
      padding: 70,
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5))`,
    },
    sectionTitles: {
      fontWeight: "400",
      fontSize: "48px",
      color: "black",
    },
    btn1: {
      fontSize: "16px",
      color: "black",
      borderRadius: 10,
      paddingRight: "28px",
      paddingLeft: "28px",
      paddingTop: "15px",
      paddingBottom: "15px",
      marginTop: "10px",
      letterSpacing: "1px",
      backgroundColor: "white",
      fontWeight: "bold"
    },
    btnHover1: {
      fontSize: "16px",
      color: "black",
      borderRadius: 10,
      paddingRight: "28px",
      paddingLeft: "28px",
      paddingTop: "15px",
      paddingBottom: "15px",
      marginTop: "10px",
      letterSpacing: "1px",
      backgroundColor: "white",
      fontWeight: "bold",
      backgroundImage: `linear-gradient(180deg, rgba(252, 206, 192, 1),
      rgba(138, 80, 128, 1))`,
    },
    video: {
      zIndex: -1,
      position: "absolute",
      width: "100%",
      marginTop: -110
    },
    sectionText: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      justifyContent: "space-around",
      minWidth: "100%",
      fontSize: "18px",
      color: "grey",
      fontWeight: 500,
      paddingRight: 100,
      paddingLeft: 100,
      fontStyle: "italic",
      marginBottom: 40,
    },
    cards: {
      height: "400px",
      width: "350px",
      margin: 15,
      boxShadow: "0px 0px 30px rgba(73, 78, 92, 0.15)",
      border: "1px",
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    cardsMobile: {
      height: "400px",
      width: "90%",
      margin: 15,
      boxShadow: "0px 0px 30px rgba(73, 78, 92, 0.15)",
      border: "1px",
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    cardsTitle: {
      fontWeight: 700,
      fontSize: "25px",
      marginBottom: "15px",
      marginTop: "15px",
      color: "#111",
    },
    cardsSub: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#777",
      marginBottom: "30px",
    },
    cardsBody: {
      fontSize: "16px",
      color: "#777",
    },
    cardImg: {
      width: "80px",
      alignSelf: "center",
    },
    divider: {
      display: "block",
      width: "60px",
      height: "3px",
      backgroundImage: `linear-gradient(0deg, rgba(252, 206, 192, 1),
      rgba(138, 80, 128, 1))`,
      marginTop: 10,
      marginBottom: 50,
    },
  };

  return (
    <div style={styles.container}>
      <NavBar></NavBar>


      <div style={styles.videoSection}>
        <img
          src={"/assets/img/bncoaching.png"}
          style={{ width: 200, alignSelf: "center", marginBottom: 50 }}
          alt="logo"
        />
        <p
          style={{
            fontSize: "20px",
            color: "lightgrey",
            marginBottom: "10px",
            fontStyle: "italic",
          }}
        >
          Développe tes aptitudes à l'aide d'un coach personnel,
          augmente tes performances, change de dimension
        </p>
        <br></br>
        <p
          style={{
            fontSize: "24px",
            color: "white",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          Mets toi en relation avec un Personal Trainer expert
          <br /> pour atteindre tes objectifs
        </p>
        <p
          style={{
            fontSize: "30px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          Trust The Progress
        </p>
        <a href="#what" style={!styleHover ? styles.btn1 : styles.btnHover1} onMouseEnter={() => setStyleHover(true)} onMouseLeave={() => setStyleHover(false)}>
          MAIS DE QUOI ME PARLES-TU ?
        </a>
        <video autoPlay loop muted id="video" style={styles.video}>
          <source src={"/assets/img/video.mp4"} type="video/mp4"></source>
        </video>
      </div>


      <div id="what" style={styles.sections}>
        <h1 style={styles.sectionTitles}>Qu'est ce que Ball'N Coaching ?</h1>
        <span style={styles.divider}></span>
        <div
          style={
            isDesktopOrLaptop
              ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }
              : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
          }
        >
          <div style={isDesktopOrLaptop ? styles.cards : styles.cardsMobile}>
            <div>
              <FontAwesomeIcon style={{ color: "black" }} icon={faAddressBook} size="4x" />
            </div>
            <p style={styles.cardsTitle}>
              Mise en relation
            </p>
            <p style={styles.cardsBody}>
              On te met en relation avec un personal Trainer trié avec soin autour de toi, tu n'as plus qu'à t'entraîner
            </p>
          </div>
          <div style={isDesktopOrLaptop ? styles.cards : styles.cardsMobile}>
            <div>
              <FontAwesomeIcon style={{ color: "black" }} icon={faPhotoVideo} size="4x" />
            </div>
            <p style={styles.cardsTitle}>Service personnalisés</p>
            <p style={styles.cardsBody}>
              Bénéficie de services personnalisés, shooting photo, vidéo, personal branding…
            </p>
          </div>
          <div style={isDesktopOrLaptop ? styles.cards : styles.cardsMobile}>
            <div>
              <FontAwesomeIcon style={{ color: "black" }} icon={faBasketballBall} size="4x" />
            </div>
            <p style={styles.cardsTitle}>
              Communauté
            </p>
            <p style={styles.cardsBody}>
              Fais partie de la communauté Basketball Ball’N Connect qui avance et progresse ensemble
            </p>
          </div>
        </div>
        <a href="https://ballncoaching.typeform.com/to/Kkw4TJ" style={!styleHover1 ? styles.btnMore1 : styles.btnMoreHover1} onMouseEnter={() => setStyleHover1(true)} onMouseLeave={() => setStyleHover1(false)}>
          COMMENCE TA
          PROGRESSION
        </a>
      </div>


      <div style={styles.backgroundSections}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <p style={{
            fontWeight: "bolder",
            fontSize: "25px",
            marginBottom: "15px",
            marginTop: "15px",
            color: "white",
            marginRight: 100
          }}>
            Ball’N Coaching One <br></br> ⛹
          </p>
          <p style={{
            fontWeight: "bolder",
            fontSize: "25px",
            marginBottom: "15px",
            marginTop: "15px",
            color: "white",
          }}>
            Ball’N Coaching Pool <br></br> ⛹⛹⛹⛹
          </p>
        </div>
        <p style={{
          fontSize: "17px",
          color: "white",
        }}>
          Tu as le choix entre t’entraîner seul.e ou de travailler en groupe (4 personnes max.) et bénéficier d'un tarif réduit sur ta séance.
        </p>
      </div>


      <div style={styles.sections}>
        <h1 style={styles.sectionTitles}>Comment ça marche ?</h1>
        <span style={styles.divider}></span>
        <div>
          <a style={!styleHover2 ? styles.btnMore1 : styles.btnMoreHover1} onMouseEnter={() => setStyleHover2(true)} onMouseLeave={() => setStyleHover2(false)}>
            Joueurs/ses
          </a>
          <a style={!styleHover3 ? styles.btnMore1 : styles.btnMoreHover1} onMouseEnter={() => setStyleHover3(true)} onMouseLeave={() => setStyleHover3(false)}>
            Personal Trainers
          </a>
        </div>
      </div>

    </div>
  );
};

export default BnCoachingPage;
